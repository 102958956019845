import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { toHTML } from '../util/utils';

import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const ContactPageTemplate = ({ info, about }) => {
  return (
    <main className="bg-gray-50">
      <ContactSection 
        info={info}
      />
      <AboutSection 
        about={about}
      />
    </main>
  )
}

ContactPageTemplate.propTypes = {
  info: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string
  }),
  about: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
}



/* Begin ContactPageTemplate Subcomponents */
let ContactSection = ({ info: { heading, description } }) => {
  return (
    <section className="bg-gray-50 py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24 lg:pb-16">
      <div className="relative max-w-xl mx-auto">
        <svg className="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg className="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div className="text-center">
          <h2 className="mb-6 text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-10">
            { heading }
          </h2>          
        </div>

        <div className="mt-10 sm:mt-12">
          <form name="contact_form" method="post" data-netlify="true" data-netlify-honeypot="bot-field" className="grid grid-cols-1 gap-y-4 sm:gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <input type="hidden" name="form-name" value="contact_form" />

            <div>
              <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-700">First name</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input name="first_name" id="first_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" />
              </div>
            </div>
            <div>
              <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700">Last name</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input name="last_name" id="last_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input name="email" id="email" type="email" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium leading-5 text-gray-700">Message</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <textarea name="message" id="message" rows="4" className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150"></textarea>
              </div>
            </div>           
            <div className="sm:col-span-2">
              <span className="w-full inline-flex rounded-md shadow-sm">
                <button type="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:border-green-600 focus:shadow-outline-green active:bg-green-500 transition ease-in-out duration-150">
                  Let's talk
                </button>
              </span>
            </div>
          </form>
        </div>

        <div className="mt-12 text-center text-gray-500 prose" dangerouslySetInnerHTML={{ 
          __html: toHTML(description)}}>  
        </div>
      </div>
    </section>
  )
};

let AboutSection = ({ about: { heading, description, image } }) => {
  return (
    <section className="relative bg-green-500">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">          
          <PreviewCompatibleImage          
            imageInfo={{
              image: image,
              childImageSharp: image.childImageSharp,
              alt: "Open Land Investments Contact Page Background",
              classes: "h-56 w-full object-cover lg:absolute lg:h-full",
            }}            
          />          
        </div>
      </div>

      <div className="relative py-16 pb-20 md:py-20 lg:py-32 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="mb-6 text-gray-100 text-3xl leading-9 font-extrabold tracking-tight sm:text-5xl sm:leading-10">
              {heading}
            </h2>
            <p className="mt-4 text-lg leading-7 text-gray-100 sm:mt-3">
              {description}
            </p>            
          </div>
        </div>
      </div>
    </section>
  );
};
/* End ContactPageTemplate Subcomponents */



const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ContactPageTemplate
        info={frontmatter.info}
        about={frontmatter.about}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        info {
          heading
          description
        }
        about {
          heading
          description
          image {
            childImageSharp {
              fluid(maxWidth: 700, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;